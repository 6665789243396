/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * prestige-elite-std:
 *   - http://typekit.com/eulas/0000000000000000000130e1
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-02-16 22:08:53 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=egv0rmh&ht=tk&f=18371&a=7678709&app=typekit&e=css");
@import '/node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';
@import '/node_modules/hamburgers/_sass/hamburgers/_base.scss';
@font-face {
font-family:"prestige-elite-std";
src:url("https://use.typekit.net/af/326a8d/0000000000000000000130e1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/326a8d/0000000000000000000130e1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/326a8d/0000000000000000000130e1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

.tk-prestige-elite-std { font-family: "prestige-elite-std",monospace; }

$OPlightgrey: #F5F5F5;
$OPDarkgrey: #A3A3A3;

$pageMargins: 100px;
$mobilePageMargins: 20px;
$toolbarClear: 50px;


/* breakpoints */
$lrgscreen: 1920px;
$laptopscreen: 1440px;
$smscreen: 1200px;
$tabletLS: 1024px;
$tabletPT: 768px;
$mobilelrgPT:414px;
$mobileLS:568px; 
$mobilePT:320px; 

.underlined-link{
  text-decoration: underline;
}

html, body, .app{
  height:100%;
  width:100%;
  font-family: prestige-elite-std, monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 10pt;
  -webkit-text-size-adjust: 100%;
  //font-size: 1em !important;
  h1,h2,h3,h4,p,span, button, input, select{
    font-family: prestige-elite-std, monospace;
    font-weight: 400;
    font-style: normal;
    font-size: 10pt;
    //font-size: 1em !important;
  }
}




a, a:hover, a:link, a:visited{
  text-decoration: none;
  color: black;
}

.underline{
  border-bottom: 1px solid #A3A3A3;
}

button{
  display: inline-flex;
  padding: 1.25em 2.5em;
  background-color: white;
  border:none;
  cursor: pointer;
  &__text{
    padding-right:1.25em;
  }
  &__price{
    
  }
  width:100%;
  justify-content: center;
  font-family: 'prestige-elite-std', monospace;
}
input{
  font-family: 'prestige-elite-std', monospace;
}

.button--addtocart{
  display: inline-flex;
  padding: 1.25em 2.5em;
  background-color: $OPlightgrey;
  &__text{
    padding-right:1.25em;
  }
  &__price{
    
  }
}

.App {
  background-image: white;
  text-align: center;
  
  .header{
    height:100vh;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    &__herowrapper{
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding: 0 $pageMargins;
      width:100%;
    }
    &__hero{
      width:100%;
      height: 60vh;
      img, video{
        object-fit: cover;
        height: 100%;
        /* width: 100%; */
      }
    }
    &.fullscreen{
      .header__herowrapper{
        padding: 0;
        height: 100%;
        .header__hero{
          height:100%;
          img{
            width: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width:1024px){
    .header{
      align-items: flex-end;
      .header__hero{
        max-width:1024px;
        height: calc(100vh - 120px);
        margin-bottom: $mobilePageMargins;
        img, video{
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width:1024px){
  .header__hero{
    max-width:1024px;
    height: calc(100vh - 120px);
    margin-bottom: $mobilePageMargins;
    img, video{
      width: 100%;
    }
  }
}
.page-holder{
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @media screen and(max-width:$tabletLS) {
      .text-content{
        padding-left: 1em;
        padding-right: 1em;
      }
      p{
        padding-left: 1em;
        padding-right: 1em;
      }
    }
}
/* Product Listing Section */
.product-listing-wrapper{
  padding: 0 $pageMargins;
  display:flex;
  justify-content: center;
  .product-listing{
    margin-top: 8em;
    width:100%;
    display:grid;
    /* grid-template-columns: minmax(150px,1fr) minmax(150px,1fr) minmax(150px,1fr); */
    grid-template-columns: 1fr 1fr 1fr; 
    /*grid-template-areas: 
    "a b c"
    "d d e";
    */
    column-gap: $pageMargins;
    >div{
      margin: 0 0 $pageMargins 0;
      margin-bottom: $pageMargins;
      &.product-card{
        opacity:0;
        text-align: left;
        p,span{
          padding-left:1em;
        }
        .product-card__imagewrapper{
          width: 100%;
          object-fit: cover;
          object-position: center center
        }
        div.titleinfo{
          margin-top: 1em; 
          display: flex;
          align-items: center;
          p:nth-of-type(2){
            padding-left:2.5em;
          }
          @media screen and (min-width: $tabletLS) {
            display: flex;
            align-items: center;
          }
          @media screen and (max-width: $tabletPT) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 0;
            p:nth-of-type(2){
              padding-left:1em;
            }
          }
          h3{
            display: inline;
            padding-right: 1em;
            font-size: 1em;
            font-weight: normal;
            text-transform: uppercase;
          }
        }
        .product-card__description{
          margin-top: 0;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
        /*
        &.spanning-1{
          grid-column-start: span 1;
        }
        &.spanning-2{
          grid-column-start: span 2;
        }
        */
      }
      /*
      &:nth-child(1){
        grid-area: a;
      }
      &:nth-child(2){
        grid-area: b;
      }
      &:nth-child(3){
        grid-area: c;
      }
      &:nth-child(4){
        grid-area: d;
      }
      &:nth-child(5){
        grid-area: e;
      }
      */
      &:nth-child(n){
        grid-column-start: span 1;
      }
      &:nth-child(4n){
        grid-column-start: span 2;
      }
      &:nth-child(7n){
        grid-column-start: span 2;
        grid-row-start: span 2;
        img{
          /* height: auto !important; */
        }
      }
      &:nth-child(8n){
        grid-column-start: span 1;
      }
    }
    >div{
      &:nth-child(3){
        @media screen and (max-width:$tabletLS){
          grid-row: 6;
        }
      }
    }
  }


  
  @media screen and (max-width:$mobilelrgPT){
    .product-listing{
      margin-top:8em;
      grid-template-columns: minmax(130px,1fr) minmax(130px,1fr);
    }
  }
}
@media screen and (max-width:$tabletLS){
  .product-listing-wrapper{
    padding: 0 2em;
    .product-listing{
      column-gap: 2em;
    }
  }
}
@media screen and (max-width:$smscreen){
  .product-listing-wrapper{
    padding: 0 4em;
    .product-listing{
      column-gap: 4em;
    }
  }
}

.swiper-slide{
  div{
    height: 100%;
    display: flex;
    width: 100%;
    justify-content: center;
    img{
      height: 100%;
      width: 100%;
      object-position: center center;
      object-fit: contain;
      max-height: 85vh;
    }
  }
}

.product-zoom-view{
  z-index: 1000000 !important;
  &.is-visible{
    display: block;
  }
  .fullscreen-click{
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    >div{
      width:50%;
      height: 100%;
    }
  }
  .exit{
    position: absolute;
    right: $toolbarClear;
    top: $toolbarClear;
    z-index: 2;
    cursor: pointer;
    img{
      height:14.4px;
    }
  }
  width: 100%;
  height: 100%;
  top:0;
  position: fixed;
  z-index: 9998;
  display: none;
  .swiper-container{
    height: 100%;
    .swiper-slide{
      background-size: cover;
      background-position: center;
      text-align: center;
      font-size: 18px;
      background: #fff;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }
}
.page{
  padding : 0 $pageMargins;
  display:flex;
  justify-content: center;
  position: relative;
  height:100vh;
  
  &.fullscreen{
    overflow: hidden;
    height: 100vh;
    flex-direction: column;
  }
  .page-journal{
    position: relative;
    overflow: visible;
    width: 100%;
    height: 100%;
    /* width: 30%; */
    margin-top: 8em;
    .draggable{
      position:absolute;
      opacity:0;
    }
    p{
      text-align: left;
    }
  }
  .footnotes{
    min-height: 8em;
    text-align: left;
    width: 30%;
  }
  @media (max-width: $tabletPT) {
    .footnotes{
      width:auto;
    }
  }
  .page-product{
    width:100%;
    display:grid;
    grid-template-columns: minmax(150px,1fr) minmax(150px,1fr) minmax(150px,1fr);
    column-gap: $pageMargins;
    text-align: left;
    >div{
      display: flex;
      align-items: center;
      >div{
        height:auto;
        width:100%
      }
    }
    @media (max-width: $tabletPT) {
      >div{
        display: block;
      }
    }
    &__gallery{
      grid-column-start: span 2;
      position: relative;
      opacity:0;
      &__content{
        position:static;
        
        
        .swiper-pagination.swiper-pagination-bullets{
          bottom:auto;
          padding-top:2em;
        }
      }
    }
    &__details{
      grid-column-start: span 1;
      &__content{
        display:flex;
        justify-content: left;
        align-items: center;
        >div:first-child{
          opacity:0;
        }
        .product__title{
          margin-bottom:1.5em;
          p{
            margin-bottom:2.5em;
          }
        }
        .product__description{
            margin-bottom:1.5em;
        }
        .product__size{
          margin-bottom:2em;
          p{
            span:first-child{
              display: block;
              margin-bottom: 0.5em;
            }
          }
        }

      }
    }
  }
    
  @media screen and (max-width: $tabletPT) {
    .page-product{
      margin-top:100px;
      display: flex;
      flex-direction: column;
      .page-product__details{
        align-items: baseline;
        .page-product__details__content{
          align-items: baseline;
          margin-bottom: 100px;
        }
      }
      div.addtocart--wrapper{
        position: fixed;
        width: calc(100% - 40px);
        bottom: 0;
        background-color: white;
        z-index: 20;
        .button--addtocart{
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 1.25em 0;
          /* position: fixed; */
          bottom: 0;
          margin-bottom: 20px;
          >div{
            width:50%;
            text-align: center;
          }
        }
      }
      &__gallery{
        &__content{
          margin-bottom: 100px;
        }
      }
    }
  }

  .mc_embed_signup{
    form{
      position:relative;
      .optionalParent{
        position: absolute;
        top: 28px;
        right: 0;
      }
      input[type=submit]{
        color: dimgrey;
        border-bottom: none;
      }
    }
  }

  .page-information{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 8em;
    column-gap: 8em;
    position: relative;
    text-align: left;   
    >div{
      grid-row: span 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      opacity:0;
      &:last-of-type{
        grid-column: 2 / span 2;
      }
      input{
        border: none;
        border-bottom: 1px solid lightgray;
        width: 100%;
        color: lightgray;
      }
      img{
        width:100%;
      }
      div.bottom-of-bars{
        position: absolute;
        bottom: 30px;
        a{
          padding-right:2em;
        }
        p{
          span{
            height:1em;
            visibility: hidden;
          }
        }
      }
      .bottom-of-bars-mobile{
        display: none;
      }
    }
  }
  .page-journal{
    width:100%;

    .dragbox{
      transition: left 1s, right 1s, top 1s, bottom 1s;
    }
    .draggable{
      display:block;
      background-color: black;
    }
    .dragbox:not(.draggable){
      width:100%;
    }
  }
}
@media (max-width: $tabletLS) {
  .page{
    padding: 0 $mobilePageMargins;
    
    .page-product{
      column-gap: 4em;
    }
  }
}

@media (max-width: $tabletPT) {
  .page{
    height:auto;
  }
}

@media screen and (max-width:$tabletLS){
  .page-information{
    margin-top: $pageMargins;
    display: flex !important;
    flex-direction: column;
    >div{
      &.image{
        height: 500px;
      }
      grid-row: 2/span 1;
      align-self: center;
      /* width: 50%; */
      
      object-fit: contain;
      max-width: 1440px;
      text-align: left;
      width:100%;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .bottom-of-bars{
        display: none;
      }
      .bottom-of-bars-mobile{
        display: block !important;
      }
    }
  }
}



.cart-bar{
  z-index:10000;
  height:100%;
  position:fixed;
  right:-100%;
  max-width: 500px;
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: $OPlightgrey;
  transition: right 1s;
  td{
    width: 50%;
  }
  &.open{
    right:0;
  }
  &__contentwrapper{
    overflow-y: scroll;
    padding: 0 $toolbarClear;
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: $tabletPT){
      padding: 0 20px;
      margin-top: 40px;
    }
    .cart-bar__details{
      position: absolute;
      bottom: 20px;
      width: calc(100% - 100px);
    }
    @media (max-width: $tabletPT){
      .cart-bar__details{
        width: calc(100% - 40px);
      }
    }
  }
  &__table{
    text-align:left;
    width:100%;
    margin-bottom:4em;
    tr{
      td:nth-child(2){
        div{
          margin-left: 2em;
        }
      }
    }
    .total-row td{
      padding-top:1.5em;
    }
  }
  &__close-btn{
    cursor: pointer;
    position: absolute;
    top: $toolbarClear;
    right: $toolbarClear;
    img{
      height: 14.4px;
    }
    @media (max-width: $tabletPT){
      right: 20px;
    }
  }
  &__items{
    .cartitem{
      margin-bottom:30px;
      >table{
        >tr{
          >td{
            width:50%;
            text-align:left;
            vertical-align:top;
          }
        }
      }
      &__image{
        width:100%;
        img{
          width:100%;
          height:auto;
        }
      }
      &__details{
        padding-left:2em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        align-items: flex-start;
        text-align: left;
        >div{
          p:first-child{
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media (max-width: $tabletPT){
  .cart-bar{
    width: 100%;
    max-width: 100% !important;
  }
}
.footer-sticky-bar-wrapper{
  position: fixed;
  bottom:0;
  width: 100%;
  left:0;
  z-index:200;
  text-align: left;
  transition: bottom 1s;
  &.hidden{
    bottom:-30vh;
  }
  .footer-sticky-bar{
    &__link{
      border-bottom: 1px solid #A3A3A3;
    }
    &__btn{
      cursor:pointer;
    }
    left: 0;
    background-color: #F5F5F5;
    height:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 0 $pageMargins;
    padding: 20px 100px;
    >div{
      height:100%;
      align-items: center;
      &:first-child{
        padding-right:40px;
      }
    }
  }
}
@media (max-width: $tabletPT){
  .footer-sticky-bar-wrapper{
    .footer-sticky-bar{
      padding: 20px $mobilePageMargins;
    }
  }
}




.toolbar{
  display:flex;
  position: fixed;
  z-index:9999;
  width: 100%;
  margin-top: $toolbarClear;
  >div{
    flex-grow:1;
    text-align: center;
    width:33.33333%;
    &:first-child{
      text-align:left;
      padding-left:$pageMargins;
      a{
        
      }
    }
    &:last-child{
      text-align:right;
      padding-right:$pageMargins;
    }
    .desktop-links{
      padding-left:1em;
      display:flex;
      .link-wrapper{
        padding-right: 3.5em;
      }
    }
  }
  .logo{
    img{
      height:0.7em;
    }
  }
  .cart-btn{
    span{
      padding-right: 1em;
      cursor: pointer;
    }
  }
  a{
    span.underlined{
      position:relative;
      display: inline-block;
      &:after{
        content:"";
        position: absolute;
        left:0;
        bottom:-3px;
        height:1px;
        width:100%;
        background-color: #A3A3A3;
        transition: width 0.3s;
      }
    }
    span.underlined-anim{
      position:relative;
      display: inline-block;
      &:after{
        content:"";
        position: absolute;
        left:0;
        bottom:-3px;
        height:1px;
        width:0;
        background-color: #A3A3A3;
        transition: width 0.3s;
      }
      &:hover{
        &:after{
          width:100%;
        }
      }
    }
  }
  
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


/* Written Text */

.text-holder{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  text-align: left;
  padding-top:100px;
  opacity:0;
  .text-content{
    grid-column: 3 / span 3;
    margin-bottom: 50px;
  }
  .table-wrapper{
    width: 100%;
    overflow: auto;
    table{
      width: 100%;
      overflow: auto;
    }
  }
  table{
    th{
      background-color: $OPlightgrey;
      font-weight: normal;
    }
    &.inline-2:nth-child(2n){
      grid-column: 2 / span 2;
    }
    &.inline-2:nth-child(2n-1){
      grid-column: 5 / span 2;
    }
    &.col-3{
      tr{
        td, th{
          width: 25%;
        }
        td:first-child,th:first-child{
          width: 50%;
        }
      }
    }
    
    tr:nth-child(2n){
      background-color: white;
    }
    tr:nth-child(2n-1){
      background-color: $OPlightgrey;
    }
    @media (max-width:$tabletPT) {
      &.inline-2:nth-child(2n){
        grid-column: 1 / 8;
      }
      &.inline-2:nth-child(2n-1){
        grid-column: 1 / 8;
      }
    }
    td, th{
      padding:10px 10px;
    }
  }
}

@media (max-width: $tabletPT){
  .text-holder{
    width: calc(100% - 40px);
    margin: 0 20px;
    .text-content{
      grid-column: 1 / 8;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hamburger{
  padding:0;
  display:none;
  width: auto;
  transition: margin-top 0.5s;
  &.is-active{
    margin-top: 5px;
  }
}

.hamburger-box{
  height: 0.9em;
  padding-left: 1em;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 1.5em;
  height: 1px;
  background-color: black;
}
.hamburger-inner::before{
  top:0;
}
.hamburger--collapse .hamburger-inner::after{
  top:-11px;
}


/* Menu */

.overlay--menu{
  display: none;
  position: fixed;
  z-index: 9998;
  height:100%;
  width:100%;
  background-color: white;
  .menu-wrapper{
    overflow: scroll;
    flex:1;
    display:grid;
    padding: $pageMargins;
    grid-template-rows: 1fr 1fr ;
    grid-template-columns: 1fr 1fr ;
    nav a{
      display:block;
      text-align: left;
      margin-bottom:1em;
    }
    .menu1{
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      padding-left: 1em;
      nav{
        margin-bottom: 30px;
      }
    }
    .menu2{
      text-align: left;
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      padding-left: 1em;
      @media (orientation: landscape) {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      input{
        border: none;
        border-bottom: 1px solid lightgray;
        width: 100%;
        &[type=submit]{
          margin-top: 5px;
          width: 100px;
          float: right;
        }
      }
    }
  }
    
}


@media (orientation: landscape) and (max-width: $mobileLS){
  .menu-wrapper{
    grid-template-rows: 1fr;
    .menu2{
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
  }
}

@media (max-width: $tabletLS){
  .toolbar{

    .desktop-links{
      display: none !important;
    }
    .hamburger{
      display:block;
    }
  }
  .overlay--menu.is-open{
    display:flex;
  }
}

.swiper-pagination-bullet-active{
  background: black !important;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
  margin: 0 15px !important;
}

.swiper-pagination-bullet {
  width: 6px !important;
  height: 6px !important;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: 10px !important;
  left: auto !important;
  /* bottom: 20px; */
  margin-top: -50px !important;
  left: 80px !important;
  top:auto !important;
  color: black !important;
  font-size: 12px;
  display: none !important;
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: 30px !important;
  right: auto;
  top:auto !important;
  margin-top: -50px !important;
  color: black !important;
  display: none !important;
}

.swiper-button-prev, .swiper-button-next{
  background: white;
  height: 10px !important;
  width: 10px !important;
  text-align: center;
  padding: 10px;
  border-radius: 67px;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 12px !important;
}

.page-product__gallery__content:hover{
  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    display: flex !important;
  }
  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    display: flex !important;
  }
}

@media (max-width: $tabletPT){
  .page-product__gallery__content{
    .swiper-button-next, .swiper-button-prev{
      display:none !important;
    }
    &:hover{
      .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        display: none !important;
      }
      .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        display: none !important;
      }
    }
  }
}


.dropdown{
  position:relative;
  border-bottom: 1px solid #A3A3A3;
  display:inline;
  select{
    position: relative;
    background: transparent;
    border: none;
    font-family: 'prestige-elite-std';
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-left: 0;
    margin-left: 0;
    border-radius: 0;
    padding-right: 2em;
    z-index: 20;
  }
  img{
    height: 1em;
    position: absolute;
    right: 0;
    z-index:10;     
    bottom: 0;                                                                                                                                                                                                                               
  }
}

.animated-dropdown{
  display: inline;
  nav{
    position: absolute;
    a{
      display:block;
      padding-top: 1em;
      opacity: 0;
    }
  }
}

.animated--fadeIn{
  opacity:0;
}

@media screen and (max-width:$tabletPT){
  .product-listing-wrapper{
    padding: 0 $mobilePageMargins;
    .product-listing{
      grid-template-columns: minmax(100px,1fr) minmax(100px,1fr); 
      margin-top:8em;
      column-gap: 20px;
      row-gap: 20px;
      /* grid-template-columns: 1fr 1fr; */
      grid-template-areas: 
      "a b"
      "d d"
      "c e";
      >div{
        margin: 0 0 40px 0;
        >a{
          >p{
            display:none;
          }
        }
      }
    }

  }

  .menu{
    .menu-wrapper{
      padding: 100px 20px 20px;
    }
  }

  .App .header__herowrapper {
    padding: 0 $mobilePageMargins;
  }

}

@media (max-width: $tabletLS){
  
  .toolbar > div:last-child{
    padding-right: $mobilePageMargins;
  } 
  .toolbar > div:first-child{
    padding-left: $mobilePageMargins;
  }
}